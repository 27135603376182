import React from "react";
import { Link, GatsbyLinkProps } from "gatsby";
import { withContext } from "~context";
import { PageLayout } from "~components/templates/page-layout";
import { Button } from "~components/utils/button";
import { Category } from "~context/ContactContext";
import * as styles from "./index.module.scss";

type State = {
  category?: Category;
  downloadLink?: string;
};

type Props = GatsbyLinkProps<State>;

const Component: React.FC<Props> = (props) => {
  const { state } = props;
  const category = state?.category;
  const downloadLink = state?.downloadLink;

  if (downloadLink) {
    return (
      <PageLayout
        paddingPattern="larger-than-header"
        meta={{ title: "フォームの送信が完了しました" }}
      >
        <div className={styles.Thankyou}>
          <h1>お問い合わせありがとうございます</h1>
          <p className={styles.Thankyou_Text}>
            {`フォームの送信が完了しました。
チェックリストは下のボタンからダウンロードすることができます。
今後ともFlatt Securityをよろしくお願いいたします。`}
          </p>
          <a href={`${downloadLink}`}>
            <Button buttonSize="Large">ダウンロードはこちら</Button>
          </a>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout meta={{ title: "フォームの送信が完了しました" }}>
      <div className={styles.Thankyou}>
        <h1>お問い合わせありがとうございます</h1>
        <p className={styles.Thankyou_Text}>
          {["assessment"].includes(category || "")
            ? `ご入力いただいたメールアドレスに資料をお送りいたしました。
担当者よりお電話、もしくはメールにてご連絡させていただくことがあります。
今後ともFlatt Securityをよろしくお願いいたします。`
            : `フォームの送信が完了しました。
内容を確認の上、担当者よりお電話、もしくはメールにてご連絡させていただきます。
今後ともFlatt Securityをよろしくお願いいたします。`}
        </p>
        <Link to="/">
          <Button buttonSize="Large">トップへ戻る</Button>
        </Link>
      </div>
    </PageLayout>
  );
};

export default withContext(Component);
